<template>
  <div>
    <b-card
      :title="$t('Update Checkin')"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
        >
          <div class="form-label-group">
            <b-form-input
              v-model="data.chassis_number"
              class="mt-2"
              :state="errors.chassis_number ?false:null"
              placeholder="Chassis_number"
            />
            <small
              v-if="errors.chassis_number"
              class="text-danger"
            >{{ errors.chassis_number[0] }}</small>
            <label>{{ $t('Chassis_number') }}</label>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div>
            <label>{{ $t('Cr') }}</label>
            <v-select
              v-model="data.cr"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="['Container','ROR','Land_shipping','Other']"
            />
            <small
              v-if="errors.cr"
              class="text-danger"
            >{{ errors.cr[0] }}</small>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div>
            <label>{{ $t('Created_year') }}</label>
            <cleave
              v-model="data.created_year"
              class="form-control"
              :class="errors.created_year ?'is-invalid':null"
              :options="{date: true, datePattern: ['Y']}"
              placeholder="Created_year"
            />
            <small
              v-if="errors.created_year"
              class="text-danger"
            >{{ errors.created_year[0] }}</small>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div>
            <label>{{ $t('Type') }}</label>
            <v-select
              v-model="data.type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="['Normal','Assa']"
            />
            <small
              v-if="errors.type"
              class="text-danger"
            >{{ errors.type[0] }}</small>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div>
            <label>{{ $t('Check_in_date') }}</label>
            <b-form-datepicker
              v-model="data.check_in_date"
              placeholder="Check_in_date"
              :state="errors.check_in_date ?false:null"
            />
            <small
              v-if="errors.check_in_date"
              class="text-danger"
            >{{ errors.check_in_date[0] }}</small>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="form-label-group">
            <b-form-input
              v-model="data.technical_detection"
              class="mt-2"
              :state="errors.technical_detection ?false:null"
              :placeholder="$t('technical_detection')"
            />
            <small
              v-if="errors.technical_detection"
              class="text-danger"
            >{{ errors.technical_detection[0] }}</small>
            <label>{{ $t('technical_detection') }}</label>
          </div>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <div>
            <label>{{ $t('loginStore') }}</label>
            <b-form-datepicker
              v-model="data.login"
              :placeholder="$t('loginStore')"
              :state="errors.login ?false:null"
            />
            <small
              v-if="errors.login"
              class="text-danger"
            >{{ errors.login[0] }}</small>
          </div>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <div>
            <label>{{ $t('logoutStore') }}</label>
            <b-form-datepicker
              v-model="data.logout"
              :placeholder="$t('logoutStore')"
              :state="errors.logout ?false:null"
            />
            <small
              v-if="errors.logout"
              class="text-danger"
            >{{ errors.logout[0] }}</small>
          </div>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <label>{{ $t('ibroker') }}</label>
          <v-select
            v-model="data.ibroker"
            :options="Ibrokers"
            :reduce="Ibrokers => Ibrokers.id"
            label="name"
          />
          <small
            v-if="errors.ibroker"
            class="text-danger"
          >{{ errors.ibroker[0] }}</small>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <label>{{ $t('Ebroker') }}</label>
          <v-select
            v-model="data.ebroker"
            :options="Ebrokers"
            :reduce="Ebrokers => Ebrokers.id"
            label="name"
          />
          <small
            v-if="errors.ebroker"
            class="text-danger"
          >{{ errors.ebroker[0] }}</small>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <label>{{ $t('Owner') }}</label>
          <v-select
            v-model="data.owner"
            :options="Owners"
            :reduce="Owners => Owners.id"
            label="name"
          />
          <small
            v-if="errors.owner"
            class="text-danger"
          >{{ errors.owner[0] }}</small>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <label>{{ $t('Car') }}</label>
          <v-select
            v-model="data.car"
            :options="Cars"
            :reduce="Cars => Cars.id"
            label="name"
          >
            <template #option="{ manufacturer,model }">
              <span> {{ manufacturer }} ({{ model }})</span>
            </template>
          </v-select>
          <small
            v-if="errors.car"
            class="text-danger"
          >{{ errors.car[0] }}</small>
        </b-col>

        <b-col
          cols="12"
          md="6"
        >
          <div>
            <label>{{ $t('store') }}</label>
            <v-select
              v-model="data.store"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="['0','1','2','3']"
            />
            <small
              v-if="errors.store"
              class="text-danger"
            >{{ errors.store[0] }}</small>
          </div>
        </b-col>

      </b-row>
      <b-row>
        <b-col
          cols="6"
          class="mt-3"
        >
          <b-button
            variant="gradient-danger"
            @click="data={},errors=[]"
          >
            {{ $t('Reset Form') }}
          </b-button>
        </b-col>
        <b-col
          cols="6"
          class="d-flex justify-content-end mt-3"
        >
          <b-button
            variant="gradient-success"
            @click="update"
          >
            {{ $t('Update') }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      errors: [],
      fileErrors: '',
    }
  },
  computed: {
    Ibrokers() { return this.$store.getters['ibroker/GetElements'] },
    Ebrokers() { return this.$store.getters['ebroker/GetElements'] },
    Owners() { return this.$store.getters['owner/GetElements'] },
    Cars() { return this.$store.getters['car/GetElements'] },
    Element() { return this.$store.getters['checkin/GetElement'] },
  },
  created() {
    this.$store.dispatch('ibroker/GetElements')
    this.$store.dispatch('ebroker/GetElements')
    this.$store.dispatch('owner/GetElements')
    this.$store.dispatch('car/GetElements')
    this.getData()
  },
  methods: {
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    getData() {
      this.$store.dispatch('checkin/GetElement', this.$route.params.checkin).then(() => {
        this.data = this.Element
      })
    },
    update() {
      this.errors = []
      this.$store.dispatch('checkin/Update', this.data).then(() => {
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
